import React from "react";
import styles from '@/styles/PremiumCards/not-included.module.scss';

interface Props {
  data: {
    text: string,
  }[],
  sku: string | number,
}


const NotIncluded = (props:Props) => {

  return (
    <div className={styles.not_included}>
      <p>What's not included</p>
      <ul className={styles.data_list}>
        {props.data.map((item, index) => {
          return (
            <li key={`${props.sku}-not-included-${index}`}>
              {item.text}
            </li>
          )
        })}
      </ul>
    </div>
  )
}


export default NotIncluded;
