import React from "react";
import PlanTab from "./planTab"
import styles from '../../../styles/modules/components/device.module.scss';
import { sortPlans } from "../../plan/utils";

interface state {
    sku: any
}


class Plans extends React.Component<any,any> {

    state:state = {
        sku: null
    }
    
    openOneTab = (sku:any) => {
        this.setState({
            sku:sku
        })
    };

    render(){
        const onePlan = this.props.plans && this.props.plans.length == 1
        const new_plans = sortPlans(this.props.plans, this.props.sku_display_config)

        return(
            <div>
                <div className={styles.plans_wrap} id='scrollToTop' style={{height:this.props.maxHeight ? `${this.props.maxHeight}` : 'auto',overflowY:'auto',width:'100%'}}>
                    {
                        new_plans && new_plans.map((product,index) => {
                            let planTabProps = {
                                ...product,
                                index: index,
                                key: product.sku,
                                sku: product.sku,
                                brand: this.props.brand,
                                setSelectedPlan: this.props.setSelectedPlan,
                                loadPlanStates: this.props.loadPlanStates,
                                quantity: product.quantity,
                                loadCart: this.props.loadCart,
                                onePlan: onePlan,
                                expanded: onePlan || (product.sku === this.state.sku),
                                openOneTab: this.openOneTab,
                                force: this.props.force,
                                closeModal: this.props.closeModal,
                                device: this.props.device,
                                crossellSkip: this.props.crossellSkip,
                                fromCart: this.props.fromCart,
                                hasBrands: this.props.hasBrands,
                                handleIncrementForBrand: this.props.handleIncrementForBrand,
                                sku_display_config: this.props.sku_display_config
                            }
                            return <div key={product.sku} className={styles.selection__input} style={{margin: "auto 10px", marginBottom: "15px"}}>
                                <PlanTab {...planTabProps} />
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }
}

export default Plans
