import React from 'react';
import {connect} from 'react-redux';
import styles from '../../styles/modules/components/device.module.scss';
import Button from '../common/ui-components/input/Button';
import {toggleCartAlert} from '../../redux/actions/cartActions';

const DeleteAlert = (props:any) => {
  const proceed = () => {
    props.onProceed(props.cart.cartAlertData.data)
  }
  
  return (
    <React.Fragment>
      <div className={styles.cart_alert}>
        <h5 className={styles.cart_alert__title}>Attention!</h5>
        <div className={styles.cart_alert__text}>
          You are about to remove the last item in your cart. Once removed, you will be redirected to the homepage. Do you want to proceed?
        </div>
      </div>
      <div className={styles.selection_actions_common}>
          <Button
            onClick={() => {props.onClick()}}
            text="No"
          />
          <Button
            onClick={() => {proceed()}}
            text="Yes"
          />
        </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state:any) => {
  return {
    cart: state.cart,
  }
}

const mapDispatchToProps = (dispatch:any) => {
  return {
    toggleCartAlert: (data:any) => dispatch(toggleCartAlert(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAlert)
