import React from 'react';
import styles from '@/styles/PremiumCards/product-rating.module.scss';

interface Props {
  details: {
    rating: number,
    count: number
  }
}


const ProductRating = (props:Props) => {
  const {rating, count} = props.details;

  const getStarDistribution = () => {
    const fullStars:number = Math.floor(rating);
    const halfStars:number = (rating - fullStars > 0) ? 1 : 0;
    return {fullStars, halfStars};
  }

  const getStars = () => {
    const {fullStars, halfStars} = getStarDistribution();
    const maxStars = 5;

    let starList:any = [];
    let starCounter:number = 0;

    while (starCounter < maxStars) {
      if (starCounter < fullStars) {
        starList.push(
          <div className={`${styles.star} ${styles.full_star}`}></div>
        )
      } else if (starCounter < (fullStars + halfStars)) {
        starList.push(
          <div className={`${styles.star} ${styles.half_star}`}></div>
        )
      }
      else {
        starList.push(
          <div className={`${styles.star} ${styles.empty_star}`}></div>
        )
      }

      starCounter += 1;
    }

    return starList;
  }

  return (
    <div className={styles.product_rating}>
      {getStars().map(star => star)}
      <p className={styles.rating_text}>{rating} ({count})</p>
    </div>
  )
}


export default ProductRating;
