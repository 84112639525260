import React, { Fragment} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import clsx from 'clsx';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {connect} from 'react-redux';
import MediaQuery from "react-responsive";
import { addToCart, toggleCartAlert, patchCartItem, removeItem } from '../../../redux/actions/cartActions';
import AddCartButton from '../../common/ui-components/AddCartButton';
import styles from '../../../styles/modules/components/device.module.scss';
import {formatNumber} from '../../../utils/index';
import { motion } from 'framer-motion';
import {viewItem, selectItem} from '../../../tracking/ecommerceTracking';
import {selectPlanType} from '../../../tracking/planPageTracking';
import DeleteAlert from '../../cart/DeleteAlert';
import UIModal from '../../common/ui-components/surface/UIModal';
import { useRouter } from "next/router";
import { getUtmParams } from '../../../utils';
import {formatDate} from '../../../utils';
import {formatCardTitle} from '../utils';
import {planTypeClickMoreServices, planTypeClickCrossell} from '../../../tracking/cartPageTracking';
import { skuCardExpanded } from '../../../tracking/segment/planPage';
import PlanTabMobile from '@/components/plans/Instarepair/planTabMobile';
import RateCard from "@/components/plans/RateCard";
import Image from "./../../common/Image";


const PlanTab = (props: any) => {
    const router = useRouter()
    const [response,setResponse] = React.useState(false)
    const [rateCardOpen, setRateCardOpen] = React.useState<boolean>(false)

    const handleExpandClick = () => {
      if(!props.expanded){
        skuCardExpanded({'sku_name':props.title, 'sku_price':props.price, 'category':props.category})
        viewItem(props, router.asPath.includes('cart') ? "Add Additional Modal" : "Plan Page", props.index) // GTM TRIGGER - product Click
      }
      props.openOneTab(props.expanded ? null: props.sku)
    };

    const incrementQuantity = (e:any, event=false, canContinue=false) => {
        if(props.hasBrands && props.quantity > 0 && !canContinue){
            canContinue = props.handleIncrementForBrand(props, incrementQuantity, e)
            if(!canContinue){
                return false
            }
        }

        let defaultStartDate = new Date;
        let type = 'Insta';
        if(['Basic Chimney','Island Chimney','Hob','Window AC','Split AC', 'UV Water Purifier', 'RO Water Purifier'].includes(props?.category)){
            type = 'AMCInsta';
            defaultStartDate.setDate(defaultStartDate.getDate())
        }else{
            defaultStartDate.setDate(defaultStartDate.getDate() + 1)
        }
        if(event){
            if(props?.fromCart){

                if(props.crossellSkip){

                    planTypeClickMoreServices(type, props?.category, props?.category_type);

                }else {

                    planTypeClickCrossell(type, props?.category, props?.category_type);
                }

            }else {
                selectPlanType(type, props?.category, props?.category_type) // GTM TRIGGER - InstaRepair select Plan Type for PlanPage Click
            }
            selectItem(props)
        }

        const data:any = {
            type: props.group,
            token_id: props.cart.token_id,
            channel_name: 'website'
        }
        if('id' in props && props.group == 'insta-repair'){
            data['quantity'] = props.quantity+1
            props.patchCartItem(props.id, data)
        }else{
            if(props.group=='insta-repair'){
                data['item'] = {
                    sku: props.sku,
                    quanity: 1,
                    additional_details:{
                        brand: props.brand
                    },
                }
            }else{
                data['item'] = {
                    sku: props.sku,
                    device_detail:{
                        brand: null
                    },
                    additional_details:{
                        brand: props.brand
                    },
                    preferred_plan_start_date: formatDate(defaultStartDate)
                }
            }
            data['tracking_params'] = getUtmParams()
            data['redirect'] = false
            props.addToCart(data,setResponse)    
        }
    }

    const handleAlert = (data:any) => {
        props.toggleCartAlert({toggle:false, toggleType:undefined, data:undefined})
        props.removeItem(data.data, data.quantity, true)
        if(!props.force){
            props.closeModal()
        }
        router.push('/');
      }


    const decrementQuantity = () => {
        // setResponse(false)
        if(props.group=='insta-repair'){
        props.removeItem({
            application: 'website',
            id:props.id, quantity: props.quantity,
            token_id: props.cart.token_id,
            data:props,
            source: 'decrementQuantity'
        }, 1, router.asPath.includes('cart')?false:true)
        }else{
            props.removeItem({
                application: 'website',
                id:props.id, quantity: 1,
                token_id: props.cart.token_id,
                data:props,
                source: 'decrementQuantity'
            },1,router.asPath.includes('cart')?false:true)
        }
    }

        return (
            <motion.div  className={`${styles.selection__input_item} ${styles.insta_repair}`} initial="pageInitial" animate="pageAnimate" variants={{
                pageInitial: {
                  opacity: 0
                },
                pageAnimate: {
                  opacity: 1
                }
              }}>
                <MediaQuery minDeviceWidth={768}>
                    <Card className={`${styles.root} ${styles._card}`} variant="outlined">
                    <CardContent className={styles._card_content} data-oneplan={props.onePlan}>
                            <div className={`row ${props.expanded ? styles.gray : styles.white } ${styles.sec_row}`}>
                            <div className={`${styles.border_effect} col-7 col-sm-7 col-md-7 col-lg-7`} onClick={props.onePlan || !props.isEnableAddButton ? () => {} : handleExpandClick}>
                                <h3 itemProp="name">{formatCardTitle(props.title)}</h3>
                                <span className={styles._card_desc}> {props.title_description}</span>
                                {!props.isEnableAddButton && <div className={styles.warning_error}>
                                    <Image src="/static/images/warning_icon_16.png" width="12" height="12" alt="warning" />
                                    <span>Sorry, this service is temporarily not available</span>
                                </div>}
                            </div>
                            <div className={`col-2 col-sm-2 col-md-2 col-lg-2`} style={{verticalAlign: "middle",textAlign: "right"}} onClick={props.onePlan || !props.isEnableAddButton ? () => {} : handleExpandClick}>
                                <h6 itemProp="price"> {formatNumber(props.price)}</h6>
                            </div>
                            <AddCartButton {...props} styles={styles} response={response} decrementQuantity={decrementQuantity} incrementQuantity={incrementQuantity} />
                            {!props.onePlan && props.isEnableAddButton &&
                            <div className={styles.right_toggle} onClick={props.onePlan ? () => {} : handleExpandClick}>
                                <IconButton
                                    className={clsx(styles.expand, {
                                        [styles.expandOpen]: props.expanded,
                                    })}
                                    aria-expanded={props.expanded}
                                    aria-label="show more"
                                    disableFocusRipple
                                    disableRipple
                                    size="large">
                                <ExpandMoreIcon />
                                </IconButton>
                            </div>
                            }
                        </div>
                        <div className="row">
                            <div className={`col-12 col-sm-12 col-md-12 col-lg-12`}>
                            <Collapse in={props.expanded} timeout="auto" unmountOnExit>
                                <div className={styles.selection__features}>
                                    <ul className={styles.selection__links}>
                                        {props.description_list?.map(function(item, index){
                                            return <li key={`selection-features-${index}`}className="device_selection__feature__list" >{item} </li>
                                        })}
                                    </ul>
                                </div>
                                {props.show_rate_card &&
                                <div className={styles.rate_card_button} onClick={() => {setRateCardOpen(true)}}>
                                  <p>Spare Parts Rate Card</p>
                                </div>
                                }
                                </Collapse>
                            </div>
                        </div>

                        {props.show_rate_card &&
                          <Fragment>
                            <UIModal isOpen={rateCardOpen} isPlainBG onClick={() => {setRateCardOpen(false)}} larger={true} >
                              <RateCard sku={props.sku} isOpen={rateCardOpen}/>
                            </UIModal>
                          </Fragment>
                        }
                        {props.cart.cartAlertType=="cartRemove" && <UIModal isOpen={props.cart.cartAlert} onClick={() => {props.toggleCartAlert({toggle:false, data:undefined})}}>
                            <DeleteAlert onClick={() => {props.toggleCartAlert({toggle:false, data:undefined})}} onProceed={handleAlert}/>
                        </UIModal>}
                    </CardContent>
                    </Card>
                </MediaQuery>
                {/* need to move these two in different components */}
                <MediaQuery maxDeviceWidth={767}>
                  <PlanTabMobile {...props}
                  classes={styles}
                    handleExpandClick={handleExpandClick}
                    formatCardTitle={formatCardTitle}
                    response={response}
                    incrementQuantity={incrementQuantity}
                    decrementQuantity={decrementQuantity}
                  />
                </MediaQuery>
            </motion.div>
        );
}

const mapStateToProps = (state:any) => {
    return {
      cart: state.cart,
    }
  }

const mapDispatchToProps = (dispatch:any) => {
    return {
      toggleCartAlert: (data:any) => dispatch(toggleCartAlert(data)),
      addToCart: (data:any,setResponse?:any) => dispatch(addToCart(data,setResponse)),
      patchCartItem: (id, data:any) => dispatch(patchCartItem(id, data)),
      removeItem: (item, quantity, force) => dispatch(removeItem(item, quantity, force)),
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(PlanTab)
