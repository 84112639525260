import { getParamsObjectFromString, isIrAmcAddButtonEnabled } from '../../utils'

export const appendQueryParams = (path:string, data:any, viewPlans:boolean) => {
  let formattedData = {}
  Object.keys(data).forEach((key) => {
    let value = data[key];
    while (value.includes(' ')) {
      value = value.replace(' ', '-')
    }
    formattedData[key] = value;
  });

  if(formattedData['acCapacity'] !== undefined){
    formattedData['acCapacity'] = formattedData['acCapacity'] === '<=1.5' ? 'less-than-one-and-half-tons' : 'more-than-one-and-half-tons'
  }

  let { urlParams, paramsString } = getParamsObjectFromString(path)

  if(Object.keys(urlParams).length !==0) {
    if(formattedData['device'] !== undefined) {
      if(urlParams['device'] !== undefined) {
        if(urlParams['device'] !== formattedData['device']) {
          paramsString = paramsString.replace(`device=${urlParams['device']}`, `device=${formattedData['device']}`)
        }
      } else {
        paramsString += paramsString === '' ? `device=${formattedData['device']}` : `&device=${formattedData['device']}`
      }
    }
    if(formattedData['acType'] !== undefined) {
      if(urlParams['device'] !== undefined) {
        if(urlParams['device'] !== formattedData['acType']) {
          paramsString = paramsString.replace(`device=${urlParams['device']}`, `device=${formattedData['acType']}`)
        }
      } else {
        paramsString += paramsString === '' ? `device=${formattedData['acType']}` : `&device=${formattedData['acType']}`
      }
    }
    if(formattedData['acCapacity'] !== undefined) {
      if(urlParams['capacity'] !== undefined) {
        if(urlParams['capacity'] !== formattedData['acCapacity']) {
          paramsString = paramsString.replace(`capacity=${urlParams['capacity']}`, `capacity=${formattedData['acCapacity']}`)
        }
      } else {
        paramsString += paramsString === '' ? `capacity=${formattedData['acCapacity']}` : `&capacity=${formattedData['acCapacity']}`
      }
    }
    if(formattedData['acBrand'] !== undefined) {
      if(urlParams['brand'] !== undefined) {
        if(urlParams['brand'] !== formattedData['acBrand']) {
          paramsString = paramsString.replace(`brand=${urlParams['brand']}`, `brand=${formattedData['acBrand']}`)
        }
      } else {
        paramsString += paramsString === '' ? `brand=${formattedData['acBrand']}` : `&brand=${formattedData['acBrand']}`
      }
    }
    if(formattedData['brand'] !== undefined) {
      if(urlParams['brand'] !== undefined) {
        if(urlParams['brand'] !== formattedData['brand']) {
          paramsString = paramsString.replace(`brand=${urlParams['brand']}`, `brand=${formattedData['brand']}`)
        }
      } else {
        paramsString += paramsString === '' ? `brand=${formattedData['brand']}` : `&brand=${formattedData['brand']}`
      }
    }
    if(formattedData['device_price'] !== undefined) {
      if(urlParams['device_price'] !== undefined) {
        if(urlParams['device_price'] !== formattedData['device_price']) {
          paramsString = paramsString.replace(`device_price=${urlParams['device_price']}`, `device_price=${formattedData['device_price']}`)
        }
      } else {
        paramsString += paramsString === '' ? `device_price=${formattedData['device_price']}` : `&device_price=${formattedData['device_price']}`
      }
    }
    if(urlParams['view-plans'] !== undefined) {
      if(urlParams['view-plans'] !== viewPlans) {
        paramsString = paramsString.replace(`view-plans=${urlParams['view-plans']}`, `view-plans=${viewPlans}`)
      }
    } else {
      paramsString += paramsString === '' ? `view-plans=${viewPlans}` : `&view-plans=${viewPlans}`
    }
  } else {
    if(formattedData['device'] !== '' && formattedData['device'] !== undefined) {
      paramsString += paramsString === '' ? `device=${formattedData['device']}` : `&device=${formattedData['device']}`
    }
    if(formattedData['acType'] !== '' && formattedData['acType'] !== undefined) {
      paramsString += paramsString === '' ? `device=${formattedData['acType']}` : `&device=${formattedData['acType']}`
    }
    if(formattedData['acCapacity'] !== '' && formattedData['acCapacity'] !== undefined) {
      paramsString += paramsString === '' ? `capacity=${formattedData['acCapacity']}` : `&capacity=${formattedData['acCapacity']}`
    }
    if(formattedData['acBrand'] !== '' && formattedData['acBrand']!==undefined) {
      paramsString += paramsString === '' ? `brand=${formattedData['acBrand']}` : `&brand=${formattedData['acBrand']}`
    }
    if(formattedData['brand'] !== '' && formattedData['brand']!==undefined) {
      paramsString += paramsString === '' ? `brand=${formattedData['brand']}` : `&brand=${formattedData['brand']}`
    }
    if(formattedData['device_price'] !== '' && formattedData['device_price']!==undefined) {
      paramsString += paramsString === '' ? `device_price=${formattedData['device_price']}` : `&device_price=${formattedData['device_price']}`
    }
    paramsString += paramsString === '' ? `view-plans=${viewPlans}` : `&view-plans=${viewPlans}`
  }

  if(paramsString !== '') {
    return path.split('?')[0] + '?' + paramsString
  } else {
    return path
  }
}

export const sortPlans = (plans:any, sku_display_config:any) => {
  let ir_plan:any = [];
  let amc_plan:any = [];
  let ir_disable = false;
  let amc_disable = false;

  plans && plans.map((plan) =>{
      if(sku_display_config && plan.group=='insta-repair'){
            const config = sku_display_config['CONSUMER_DISABLE_SKU_CONFIG_IR']
            let ir_temp = isIrAmcAddButtonEnabled(plan.category_type,config)
            plan.isEnableAddButton = ir_temp
            ir_plan.push(plan)
            ir_disable = !ir_temp;
        }else if(sku_display_config && plan.group=='amc'){
            const config = sku_display_config['CONSUMER_DISABLE_SKU_CONFIG_AMC']
            let amc_temp = isIrAmcAddButtonEnabled(plan.category_type,config)
            plan.isEnableAddButton = amc_temp
            amc_plan.push(plan)
            amc_disable = !amc_temp
      }
  })
  if(!amc_disable && ir_disable){
    return [...amc_plan,...ir_plan]
  }else if(!ir_disable && amc_disable){
    return [...ir_plan,...amc_plan]
  }
  return plans
}

