import React, {Fragment, useState, useEffect} from 'react';
import clsx from 'clsx';
import PremiumBadge from '@/components/plans/PremiumCards/PremiumBadge';
import VideoPlayer from '@/components/plans/PremiumCards/VideoPlayer';
import ProductRating from '@/components/plans/PremiumCards/ProductRating';
import OfferDetails from '@/components/plans/PremiumCards/OfferDetails';
import FeaturesDrawer from "@/components/plans/PremiumCards/FeaturesDrawer";
import FeaturesList from "@/components/plans/PremiumCards/FeaturesList";
import ImageCarousel from "@/components/plans/PremiumCards/ImageCarousel";
import Note from "@/components/plans/PremiumCards/Note";
import NotIncluded from "@/components/plans/PremiumCards/NotIncluded";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import AddCartButton from '../../../../../components/common/ui-components/AddCartButton';
import UIModal from '../../../../../components/common/ui-components/surface/UIModal';
import styles from '../../../../../styles/modules/components/device.module.scss';
import {formatNumber} from '../../../../../utils';
import { VisibilityWrapper } from "@/components/hoc/VisibilityHandler";
import RateCard from "@/components/plans/RateCard";
import Image from '../../../../../components/common/Image';

const PlanTabMobile = (props:any) => {
  const {classes, formatCardTitle, response, handleExpandClick, incrementQuantity, decrementQuantity} = props

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [comparePlan, setComparePlan] = useState<any>(null);
  const [rateCardOpen, setRateCardOpen] = React.useState<boolean>(false)

  const handleComparePlan = () => {
    if (comparePlan) {
      comparePlan.scrollIntoView({behavior: "smooth"})
    }
  }

  useEffect(() => {
    let element = document.getElementById("compare-plan");
    if (element) {
      setComparePlan(element);
    }
  }, [])

  return (
    <Fragment>
      <FeaturesDrawer
        isOpen={drawerOpen}
        onClick={() => {setDrawerOpen(false)}}
        features={props.premium_details.onsitego_standards?.items_list}
        banner={props.premium_details.onsitego_standards?.banner_image_url}
        standards={props.premium_details.onsitego_standards}
      />
      <Card id={`sku-${props.sku}`} className={classes.root} variant="outlined"
        style={{
          display: 'flex', flexDirection: 'row', width: "100%",
          boxShadow: props.expanded ? '0 0 10px 3px #ddd' : 'none'
        }}>
        <CardContent className={styles.white} style={{ paddingTop: "10px", paddingBottom: props.expanded ? "10px" : "0px", paddingRight: '0px',paddingLeft: '0px',marginRight: "0px",marginLeft: "0px",width: "100%"}} >
          {props.premium_details.labels?.length > 0 ? <PremiumBadge details={props.premium_details.labels[0]}/> : null}
          {props.premium_details.banner_video?.video_url ?
            (<VisibilityWrapper>
              <VideoPlayer
                details={{
                  ...props.premium_details.banner_video,
                  ...props.premium_details.onsitego_standards,
                  handleDrawer:(drawerState:boolean) => {setDrawerOpen(drawerState)},
                  drawerOpen:drawerOpen,
                }}/>
            </VisibilityWrapper>)
            : null
          }
          {
            props.premium_details.banner_images?.items?.length > 0 && !props.premium_details.banner_video?.video_url ?
            <ImageCarousel
              images={props.premium_details.banner_images?.items}
              sku={props.sku}
              imageCount={props.premium_details.banner_images?.count}
              watermark={props.premium_details.onsitego_standards}
              handleDrawer={(drawerState:boolean) => {setDrawerOpen(drawerState)}}
              drawerOpen={drawerOpen}/>
            :
            null
          }
          <div className={`row ${styles.white_row}`} style={{ display : "flex",
              alignItems : "center",
              justifyContent: "center",
              paddingLeft: "10px",paddingRight: "10px"}}>
              <div className={`col-8 col-sm-9 col-md-10`}  onClick={props.onePlan || !props.isEnableAddButton ? () => {} : handleExpandClick}>
                  {props.display_title.includes("AMC") || props.title.includes("AMC")?
                      formatCardTitle(`${props.display_title}`).length > 10 ?
                      <Fragment>
                        <h6 className={styles.premium_title}>{formatCardTitle(`${props.display_title}`)}</h6>
                        <h6 className={styles.premium_title}>({props.duration})</h6>
                      </Fragment>
                      :
                      <h6 className={styles.premium_title}>{formatCardTitle(`${props.display_title} - ${props.duration}`)}</h6>
                    :
                    <h6 className={styles.premium_title}>{formatCardTitle(props.title)}</h6>
                  }
              </div>
              <div className={`col-4 col-sm-3 col-md-2`} style={{verticalAlign: "middle",textAlign: "right"}}>
                  <h6 className={styles.premium_title}> {props.price ? formatNumber(props.price) : ""}</h6>
              </div>
          </div>
          <div className={`row ${styles.white_row}`} style={{ display : "flex",
              alignItems : "flex-start",
              justifyContent: "space-between",paddingTop: "10px",
              paddingLeft: "10px",paddingRight: "10px"
              }}>
              <div className={`col-9 col-sm-9 col-md-9`}>
                {props.is_premium ?
                  <Fragment>
                  <div>
                    {props.premium_details.rating?.rating ? <ProductRating details={props.premium_details.rating} /> : null}
                    {
                      props.premium_details.offer_details?.text?
                      <OfferDetails details={props.premium_details.offer_details} />
                      :
                      (
                        props.premium_details.onsitego_standards?.label_icon_url ?
                          <div className={styles.premium_cards}>
                          <div
                            className={styles.watermark}
                            style={{
                              background: `url(${props.premium_details.onsitego_standards.label_icon_url ? props.premium_details.onsitego_standards.label_icon_url : ''}) no-repeat center`,
                              backgroundSize: "contain,cover"
                            }}
                          ></div>
                          <div className={styles.info} onClick={() => {setDrawerOpen(!drawerOpen)}}>i</div>
                          </div>
                        :
                        null
                      )
                    }
                  </div>
                  </Fragment>
                  :
                  <div className={`${styles.insta_mobile_text}`} onClick={props.onePlan || !props.isEnableAddButton ? () => {} : handleExpandClick}>
                      <span>{props.title_description}</span>
                      {!props.isEnableAddButton && <div className={styles.warning_error}>
                            <Image src="/static/images/warning_icon_36.png" width="12" height="12" alt="warning" />
                            <span style={{color:"orange"}}> Sorry, this service is temporarily not available</span>
                        </div>}
                  </div>
                }
              </div>
              <AddCartButton {...props} styles={styles} response={response} decrementQuantity={decrementQuantity} incrementQuantity={incrementQuantity} />
          </div>
          {props.is_premium &&
            <div className={`${styles.insta_mobile_text} ${styles.premium_card_text}`} onClick={props.onePlan || !props.isEnableAddButton ? () => {} : handleExpandClick}>
                <span>{props.title_description}</span>
                {!props.isEnableAddButton && <div className={styles.warning_error}>
                    <Image src="/static/images/warning_icon_36.png" width="12" height="12" alt="warning" />
                    <span style={{color:"orange"}}> Sorry, this service is temporarily not available</span>
                </div>}
            </div>
          }
          <div className={`row`} onClick={props.onePlan || !props.isEnableAddButton ? () => {} : handleExpandClick} style={{ display : "flex",
              alignItems : "center", borderTop: '0.5px solid #ddd',
              justifyContent: "center",backgroundColor: "rgba(247,247,247,1)", paddingLeft : "0px", paddingRight: "0px", margin: "0px", marginTop: "10px" }} >
              {!props.onePlan && props.isEnableAddButton &&
              <Fragment>
                  <div className={`${styles.collapse_btn} col-9 col-sm-9 col-md-9`}>
                      CHECK ALL BENEFITS
                  </div>

                  <div className={`col-3 col-sm-3 col-md-3`} style={{backgroundColor: "rgba(247,247,247,1)", paddingLeft : "0px", paddingRight: "0px", margin: "0px", textAlign: "right"}}>
                      <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: props.expanded,
                        })}
                        aria-expanded={props.expanded}
                        aria-label="show more"
                        disableFocusRipple
                        disableRipple
                        size="large">
                      <ExpandMoreIcon />
                      </IconButton>
                  </div>
              </Fragment>
                  }
          </div>
          <div className="row" style={{paddingLeft: "10px",paddingRight: "10px",backgroundColor: "white"}} >
              <div className={`col-12 col-sm-12 col-md-12 col-lg-12`}>
              <Collapse in={props.expanded} timeout="auto" unmountOnExit>
                <div className={`${styles.selection__features} ${styles.selection__features_mobile}`} style={{paddingTop: "10px",paddingLeft: "5px"}}>
                    {props.description_list_image?.length > 0 && <FeaturesList features={props.description_list_image}/>}
                    <ul className={styles.selection__links}>
                        {props.description_list?.map(function(item, index){
                            return <li key={`feature-list-${index}`} className="device_selection__feature__list" >{item} </li>
                        })}
                    </ul>
                </div>
                {props.premium_details.not_included?.length > 0 &&
                  <NotIncluded sku={props.sku} data={props.premium_details.not_included} />
                }
                {
                  (props.premium_details.note_details?.text || (comparePlan && props.is_premium && props.title.includes("AMC"))) && <hr className={styles.premium_card_divider}/>
                }
                {comparePlan && props.is_premium && props.title.includes("AMC") && <p className={styles.compare_plan_button} onClick={handleComparePlan}>COMPARE THIS PLAN </p>}
                {
                  props.premium_details.note_details?.text && <Note text={props.premium_details.note_details?.text} />
                }
                {props.show_rate_card &&
                <div className={styles.rate_card_button} onClick={() => {setRateCardOpen(true)}}>
                  <p>Spare Parts Rate Card</p>
                </div>
                }
              </Collapse>
              </div>
          </div>
          {props.show_rate_card &&
            <Fragment>
              <UIModal isOpen={rateCardOpen} isPlainBG onClick={() => {setRateCardOpen(false)}} larger={true} >
                <RateCard sku={props.sku} isOpen={rateCardOpen}/>
              </UIModal>
            </Fragment>
          }
      </CardContent>
      </Card>
    </Fragment>
  );
}


export default PlanTabMobile;
