import React from 'react';
import UIModal from "../../../../../components/common/ui-components/surface/UIModal";
import styles from '@/styles/PremiumCards/premium-drawer.module.scss';
import commonStyles from '../../../../../styles/modules/components/device.module.scss';
import Image from "@/components/UI/Image";


interface Props {
  isOpen: boolean,
  features: {
    icon_name: string | null,
    icon_url: string,
    sub_title: string | null,
    title: string,
  }[],
  onClick: (e: React.MouseEvent) => void
  banner: string | null,
  standards: any
}


const FeaturesDrawer = (props: Props) => {
  const {isOpen, onClick} = props;
  return (
    <UIModal onClick={onClick} isOpen={isOpen}>
      {props.standards?.label_icon_url ?
        <div className={commonStyles.premium_cards}>
        <div
          className={`${commonStyles.watermark} ${commonStyles.drawer_header}`}
          style={{
            background: `url(${props.standards.label_icon_url ? props.standards.label_icon_url : ''}) no-repeat center`,
            backgroundSize: "contain,cover"
          }}></div>
        </div>
      :
      null}
      <div className={styles.premium_drawer}>
        {
          props.banner &&
          <div className={styles.banner}>
            <Image src={props.banner} width="450" height="150" alt={"o-assurance banner"}/>
          </div>
        }
        <div className={styles.features_list}>
          {
            props.features?.map((feature, index) => {
              return (
                <div key={`premium-features-drawer-${index}`} className={styles.feature}>
                  <div className={styles.feature_image}>
                    <div className={styles.image_wrapper}>
                      <Image src={feature.icon_url} alt={feature.icon_name || ''} width="60" height="60" />
                    </div>
                  </div>
                  <div className={styles.feature_information}>
                    <p className={styles.feature_title}>{feature.title}</p>
                    {
                      feature.sub_title ?
                      <p className={styles.feature_subtitle}>{feature.sub_title}</p>
                      :
                      null
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </UIModal>
  )
}

export default FeaturesDrawer;
