import React, { Fragment } from 'react';
import UIDropdownMenu from './UIDropdownMenu';
import styles from "../../../../../styles/modules/ui-components/dropdown.module.scss";

interface UIDropdownProps {
    options: {
        key: any,
        text: string,
        value: any,
        icon?: any,
        keyword?: any
    }[],
    id?: string,
    placeholder: string,
    onChange: (value) => void,
    onBlur?: (e) => void,
    value?: any,
    search?: boolean,
    className?: string,
    labelClass?: string,
    breakpoint?: number,
    selected_option?: any,
    isSearching?: boolean,
    toggleState?: (state: boolean) => boolean,
    propsError?: string,
    disabled?: boolean,
    position?: string,  // by defult bottom can add 'top' as string to chnage
    label?: string,
    labelClassname?: string,
    isRequired?: boolean,
    isOpen?: boolean,
    isClosed?: boolean,
    isActivation?: boolean,
    isOther?: boolean,
}

interface UIDropdownState {
    isOpen: boolean,
    options: {
        key: any,
        text: string,
        value: any,
        icon?: any,
        keyword?: any

    }[],
    selected_option: any,
    filteredOptions: {
        key: any,
        text: string,
        value: any,
        icon?: any
    }[],
    isSearching: boolean,
    breakpoint: number,
    isMobile: boolean,  
    searchValue: string,
}


class UIDropdown extends React.Component<UIDropdownProps, UIDropdownState> {

    constructor(props) {

        super(props);
        this.state = {
            isOpen: false,
            options: this.props.options,
            selected_option: this.props.selected_option,
            filteredOptions: [],
            isSearching: false,
            breakpoint: this.props.breakpoint || 0,
            isMobile: false,
            searchValue: ""
        }
    }

    static getDerivedStateFromProps = (props: UIDropdownProps, state: UIDropdownState) => {
        if (state.selected_option !== props.selected_option) {
            return {
                selected_option: props.selected_option
            }
        }
        if (state.options !== props.options) {
            return {
                options: props.options
            }
        }

        return null
    }

    openMenu = () => {
        if (!this.props.disabled) {
            this.setState({
                isOpen: true
            });
        }
    }

    selectValue = (e) => {
        if(this.props.isActivation && e.target.getAttribute('data-value') ===  null && this.state.searchValue === "" ){
            this.setState({
                isOpen: true,
                isSearching: true,
                searchValue: ""
            });
        } else {
            let value = e.target.getAttribute('data-value') || this.state.searchValue,
            selected_option_obj = this.state.options.find((option) => option.value.toLowerCase() == value.toLowerCase()) || {"key": value, "text": value, "value": value};
            if (document.activeElement !== null) {
                (document.activeElement as HTMLElement).blur();
            }
            if (selected_option_obj) {
                this.props.onChange(selected_option_obj);
                this.setState({
                    selected_option: selected_option_obj,
                });
            }
            this.setState({
                isOpen: false,
                isSearching: false,
                searchValue: ""
            });
        }
    }

    filterOptions = (e) => {
        this.setState({searchValue: e.target.value.trimStart()})
        const typed_value = e.target.value.trim();
        const filterFromKeyword = (options) => {
            const filter_options = options.filter((option) => {
                var len = typed_value.length;
                var found = option.keyword && option.keyword.find((key) => {
                    if (key.toLowerCase().replace(" ", '').slice(0, len).includes(typed_value.toLowerCase().replace(" ", '').slice(0, len))) {
                        return true
                    }
                })
                if (found || option.text.toLowerCase().replace(" ", '').slice(0, len).includes(typed_value.toLowerCase().replace(" ", '').slice(0, len))) {
                    return option
                }
            })
            filter_options.forEach((item, i) => {
                var len = typed_value.length;
                if (item.text.toLowerCase().replace(" ", '').slice(0, len) == typed_value.toLowerCase().replace(" ", '').slice(0, len)) {
                    filter_options.splice(i, 1)
                    filter_options.unshift(item)
                }
            })
            return filter_options;
        }
        if (typed_value.length) {
            this.setState({
                filteredOptions: filterFromKeyword(this.state.options),
                isSearching: true
            });
        }
        else {
            this.setState({
                isSearching: false
            });
        }
    }

    clearValue = (e) => {
        setTimeout(() => {
            if(!this.props.isActivation){
                e.target.value = "";
                this.setState({
                    isSearching: false,
                    isOpen: false
                });
            } else {
                this.setState({
                    filteredOptions: this.state.options,
                    isSearching: true
                });
            } 
        }, 500);
    }
    componentDidMount() {
        if(window.innerWidth < 540){
            this.setState({isMobile:true})
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.isOpen !== undefined && this.props.isOpen !== prevProps.isOpen) {
            this.setState({ isOpen: this.props.isOpen });
        }
    }
    
    render() {
        const { placeholder, search, id,className, labelClass, toggleState, propsError, disabled, position, label, labelClassname, isRequired, isActivation, isOther } = this.props;
        const { isOpen, selected_option, options, filteredOptions, isSearching, isMobile } = this.state;
        const dataObj = isSearching ? filteredOptions : options;
        return (
            <Fragment>
                {label && <label className={labelClassname ? labelClassname : `${styles.app_dropdown__labelname}`} htmlFor={id}>{label} {isRequired && <em>*</em>}</label>}
                <div id={id} role="listbox" data-valid={!(propsError)} data-generic={true} data-search={search} data-open={isOpen} className={`dropdown ${styles.app_dropdown} ${isOpen ? styles.dropdown__open : ''} ${className ? className : ''} ${disabled ? styles.dropdown__disabled : ''}`.trim()} tabIndex={0} onFocus={() => {
                    this.openMenu();
                    toggleState ? toggleState(true) : null;
                }} onBlur={(e) => {
                    this.setState({
                        isOpen: false
                    });
                    toggleState ? toggleState(false) : null;
                    this.props.onBlur && this.props.onBlur(e)
                }}>
                    <span title={placeholder} className={`${styles.app_dropdown__label} ${selected_option ? styles.afterSelection : styles.beforeSelection} ${labelClass ? labelClass : ''}`.trim()}>{selected_option ? selected_option.text : placeholder}</span>
                    {search && isOpen && <input name={id} id={id} value={this.state.searchValue} role="textbox" className={styles.app_dropdown__search} onChange={this.filterOptions} autoFocus onBlur={this.clearValue} placeholder="Type to search" />}
                    {!(isActivation && isMobile) &&<UIDropdownMenu position={position} data={dataObj} isEmpty={dataObj?.length == 0} selectValue={this.selectValue} selected_option={selected_option} disabled={disabled} id={id} isActivation={isActivation} isOther={isOther} value={this.state.searchValue}/>}
                </div>
                {!isOpen && propsError && <span className={styles.textfield__error}>{propsError} </span>}

            </Fragment>
        );
    }

}

export default UIDropdown;
