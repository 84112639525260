import React, {Component} from 'react';
import styles from '@/styles/PremiumCards/image-carousel.module.scss';
import Slider from "react-slick";
import ProgressBar from "@/components/plans/PremiumCards/ProgressBar";
import Image from "next/legacy/image";

interface Props {
  images: {
    banner_url: string,
    banner_name: string,
    priority: number,
  }[],
  sku: string | number,
  imageCount: number,
  watermark:{
    banner_image_url: string,
    label_icon_url: string ,
    items_list: {
      icon_name: string | null,
      icon_url: string,
      title: string,
      sub_title: string
    }[],
  }
  handleDrawer: any,
  drawerOpen: boolean
}

interface State {
  initTime: number,
  currentTime: number,
}

class ImageCarousel extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.imageInterval();
  }
  state:State = {
    initTime: Date.now(),
    currentTime: Date.now(),
  }

  handleSlideChange = () => {
    this.setState({
      initTime: Date.now(),
      currentTime: Date.now(),
    });
  }

  handleDrawer = () => {
    if (this.props.watermark.items_list.length > 0) {
      this.props.handleDrawer(!this.props.drawerOpen);
    }
  }

  imageInterval = () => {
    setInterval(() => {
      this.setState({
        currentTime: Date.now()
      })
    }, 500)
  }

  settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    rows: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: this.handleSlideChange,
    responsive: [{
      breakpoint: 576,
      settings: {
          slidesToShow: 1,
          slidesToScroll: 1
      }
    }]
  }

  render() {
    return (
      <div className={styles.image_carousel}>
        <div className={styles.container}>
          <div className="slider-wrap">
            <Slider className={styles.slides} {...this.settings} >
              {this.props.images.map((image, index) => {
                return (
                  <div key={`${this.props.sku}-banner-image-${index}`} className={styles.slide}>
                    <Image
                      src={image.banner_url}
                      alt={image.banner_name}
                      style={{
                        maxWidth: "100%",
                        height: "auto"
                      }} />
                  </div>
                );
              })}
            </Slider>
          </div>
          {this.props.imageCount > 1 &&
            <ProgressBar
              maxValue={4000}
              currentValue={this.state.currentTime - this.state.initTime <= 4000 ?
                this.state.currentTime - this.state.initTime
                : 4000
              }
              startsWithZero={false}/>
            }
        </div>
        {this.props.watermark?.label_icon_url ?
          <div
            className={styles.watermark}
            style={{
              background: `url(${this.props.watermark.label_icon_url ? this.props.watermark.label_icon_url : ''}) no-repeat center`,
              backgroundSize: "contain,cover"
            }}
            onClick={this.handleDrawer}>
          </div>
          :
          null
        }
      </div>
    );
  }
}

export default ImageCarousel;
