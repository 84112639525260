import React, {useState, useEffect, useRef} from 'react';

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);
  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    if (ref.current) {

      observer.observe(ref.current)
    }
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting;
}


export const VisibilityWrapper = (props:any) => {
  const ref:any = useRef();

  const wrappedChildren = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      let isVisible:{
        visible:any,
      } = {visible: useOnScreen(ref) || false}
      return React.cloneElement(child, isVisible)
    }
    return child
  })

  return (
    <div ref={ref}>
      {wrappedChildren}
    </div>
  )
}
