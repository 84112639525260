import React from "react";
import styles from '@/styles/PremiumCards/note.module.scss';

interface Props {
  text: string,
}

const Note = (props:Props) => {

  return (
    <div className={styles.note}>
      <div className={styles.badge}>NOTE</div>
      <p>{props.text}</p>
    </div>
  )
}

export default Note;
