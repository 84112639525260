import React from 'react';
import styles from '@/styles/PremiumCards/features-list.module.scss';
import Image from '../../../../../components/common/Image';

interface Props {
  features: {
    title: string,
    image: string
  }[]
}

const FeaturesList = (props:Props) => {

  const [smallScreen, setSmallScreen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (window.innerWidth < 280) {
      setSmallScreen(true);
    }
  }, [])
  
  return (
    <div className={`${styles.features_list} ${smallScreen ? styles.small_row : ''}`}>
      {props.features.map((feature, index) => {
        return (
          <div key={`premium-feature-list-${index}`} className={`${styles.feature}`}>
            <div className={styles.feature_image}>
              <div className={styles.feature_image_wrapper}>
                <Image className={styles.image} src={feature.image} alt={"feature-image"} width="100%" height="100%"/>
              </div>
            </div>
            <div className={styles.feature_text}>
              <div className={styles.text_wrapper}>
                {feature.title}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default FeaturesList;
