import React, { Fragment } from 'react';
import styles from "../../../../../styles/modules/ui-components/dropdown.module.scss";
import { DROPDOWN_ADDING_NAMES } from '../../../../../constants/activation';


const UIDropdownMenu = (props) => {
    const { data, selected_option, selectValue, isEmpty, position, id, isOther, value} = props;
    return (
        <Fragment>
            <div className={`${styles.app_dropdown__menu} dropdown__menu`} data-position={position ? position : 'bottom'} >
                <ul role="list">
                    {
                        data && data.map((option, index) => {
                            return (
                                <li role="listitem" key={`dropdown-menu-${option.value}-${index}`} className={`${styles.dropdown__menu_item} dropdown__menu__item`} title={option.text} data-selected={selected_option && selected_option.value == option.value ? true : false} onClick={selectValue} data-icon={option.icon} data-value={option.value}> <span> {option.text}</span> </li>
                            )
                        })
                    }
                    {
                        isOther && <li className={`${styles.dropdown__menu_item} ${styles.otherField} dropdown__menu__item`} onClick={selectValue}> + Add {value.length > 5 ? value.substring(0,5) + '...': value} as {DROPDOWN_ADDING_NAMES[id]}</li>
                    }
                    {
                        isEmpty && <span className={styles.dropdown__no_result}> No result found </span>
                    }
                </ul>
            </div>
        </Fragment>
    );
}

export default UIDropdownMenu;