import {pushToDatalayer} from "../utils/tracking/gtmTracking";

// Events : 1] Click
//          2] Add value
// Journey Types : 1] Insta
//                 2] AMC
//                 3] EW
//                 3] SDP
//
const mapping = {
    'Extended Warranty': 'EW',
    'Spills and Drops / Damage Protection': 'SDP',
    'Screen Protection': 'SP'
}

export const helperFunction = (cart, cartType, functionName) =>{
    try{

        const unique = {}

        if (cartType == 'protection-plan'){

            cart.items.map(function(obj) {

                unique[obj.category] = unique[obj.category] || []
                !unique[obj.category].includes(obj.product_type) ? unique[obj.category].push(obj.product_type) : null;
            })

            for (const obj in unique){
                unique[obj].map(function(item) {
                    functionName(mapping[item], obj)
                })
            }

        }else if (cartType == 'insta-repair' || cartType == 'amc' || cartType == 'AMCInsta') {
            cart.items.map(function(obj) {
                // unique[obj.category] = unique[obj.category] || []
                // !unique[obj.category].includes(obj.product_type) ? unique[obj.category].push(obj.product_type) : null;

                if(['Island Chimney','Basic Chimney','Window AC','Split AC', 'UV Water Purifier', 'RO Water Purifier'].includes(obj.category)){
                    unique[obj.category_type] = unique[obj.category_type] || []
                    !unique[obj.category_type].includes("AMCInsta") ? unique[obj.category_type].push("AMCInsta") : null;
                }else {

                    unique[obj.category] = unique[obj.category] || []
                    if(obj.group == 'insta-repair' && !(obj.category == 'Hob')){
                        !unique[obj.category].includes("Insta") ? unique[obj.category].push("Insta") : null;
                    }else if(obj.group=='amc' && !(obj.category == 'Hob')){
                        !unique[obj.category].includes("AMC") ? unique[obj.category].push("AMC") : null;
                    }else if(obj.category == 'Hob'){
                        !unique[obj.category].includes("AMCInsta") ? unique[obj.category].push("AMCInsta") : null;
                    }   
                }

            })
            for (const obj in unique){
                unique[obj].map(function(item) {
                    functionName(item, obj)
                })
            }

        } else if(cartType == "homecare") {
          cart.items.map(function(obj) {

              unique[obj.category] = unique[obj.category] || []
              !unique[obj.category].includes(obj.product_type) ? unique[obj.category].push(obj.product_type) : null;
          })

          for (const obj in unique){
              unique[obj].map(function(_item) {
                  functionName("HomeCare", obj)
              })
          }
        }

    }catch{
        console.log('error while pushing to datalater in helperFunction')
    }
}

export const trackingHelper = (category,functionName) =>{
    if(['Chimney','Hob'].includes(category)){
      functionName('AMCInsta',category)
    }else{
      functionName('AMC',category)
    }
  }

export const addPhoneNumber = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Phone Number",
        action: "Add value",
        category: "Booking Flow",
        label: "Phone Number - Add Values",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const sendOTPClick = (journeyType, deviceCategory) =>{
    try{
        const payload = {
            event: "Send OTP",
            action: "Click",
            category: "Booking Flow",
            label: "Send OTP - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const addOTP = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Enter OTP",
        action: "Add value",
        category: "Booking Flow",
        label:"Enter OTP - Add Values",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const verifyOTP = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Verify OTP",
        action: "Click",
        category: "Booking Flow",
        label:"Verify OTP - Click",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const resendOTP = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Resend OTP",
        action: "Click",
        category: "Booking Flow",
        label:"Resend OTP - Click",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const addName = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Name",
        action: "Add value",
        category: "Booking Flow",
        label: "Name - Add Values",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}
export const addNameAuto = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Name",
        action: "Auto populated",
        category: "Booking Flow",
        label: "Name - Auto populated",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const addEmail = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Email",
        action: "Add value",
        category: "Booking Flow",
        label: "Email - Add Values",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}
export const addEmailAuto = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Email",
        action: "Auto populated",
        category: "Booking Flow",
        label: "Email - Auto populated",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const addAddress = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Address",
        action: "Add value",
        category: "Booking Flow",
        label: "Address - Add Values",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}
export const addAddressAuto = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Address",
        action: "Auto populated",
        category: "Booking Flow",
        label: "Address - Auto populated",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const addPincode = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Pincode",
        action: "Add value",
        category: "Booking Flow",
        label: "Pincode - Add Values",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}
export const addPincodeAuto = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Pincode",
        action: "Auto populated",
        category: "Booking Flow",
        label: "Pincode - Auto populated",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

// ProtectionPlan(upsell),
export const addMoreServices = (journeyType, deviceCategory) =>{
    // journey type value is Extended Warranty', 'Spills and Drops / Damage Protection','Screen Protection' so taking required value from "mapping object"    
    try{
        const payload = {
        event: "Add more services-Upsell",
        action: "Click",
        category: "Booking Flow",
        label: "Add more services-Upsell - Click",
        device_category: deviceCategory,
        journey_type: mapping[journeyType],
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

// ProtectionPlan(Upsell and Crossell PLAN CLICK),
export const addMoreServicesPlanClick = (journeyType, deviceCategory, isUpsell) =>{
    // journey type value is Extended Warranty', 'Spills and Drops / Damage Protection','Screen Protection' so taking required value from "mapping object"
    try{
        if(isUpsell) {
            const payload = {
            event: "Add more services",
            action: "Click",
            category: "Booking Flow",
            label: "Add more services - Click",
            device_category: deviceCategory,
            journey_type: mapping[journeyType],
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

        } else {

            const payload = {
                event: "View Plan DropDown - Crossell",
                action: "Click",
                category: "Booking Flow",
                label: "View Plan DropDown - Crossell - Click",
                device_category: deviceCategory,
                journey_type: mapping[journeyType],
                journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

        }
    }catch{
        console.log('error while pushing to datalater')
    }
}

// ProtectionPlan(Upsell and Crossell Add to Cart CLICK),
export const upsellCrossellAddToCartClick = (journeyType, deviceCategory, isUpsell) =>{
    // journey type value is Extended Warranty', 'Spills and Drops / Damage Protection','Screen Protection' so taking required value from "mapping object"
    try{
        if(isUpsell) {

            const payload = {
            event: "Add to Cart Button  more services",
            action: "Click",
            category: "Booking Flow",
            label: "Add to Cart Button more services- Click",
            device_category: deviceCategory,
            journey_type: mapping[journeyType],
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

        } else {

            const payload = {
                event: "Add to Cart Button Additional Device - Crossell",
                action: "Click",
                category: "Booking Flow",
                label: "Add to Cart Button Additional Device - Crossell - Click",
                device_category: deviceCategory,
                journey_type: mapping[journeyType],
                journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

        }
    }catch{
        console.log('error while pushing to datalater')
    }
}

// Protection and AMC CLICK
export const addAdditinolCrossellClick = (journeyType, deviceCategory) =>{
    // journey type value is Extended Warranty', 'Spills and Drops / Damage Protection','Screen Protection' so taking required value from "mapping object"
    try{

            const payload = {
            event: "Add Additional Device - Crosssell",
            action: "Click",
            category: "Booking Flow",
            label: "Add Additional Device - Crosssell - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

// Protection
export const crossellDeviceTypeProtection = (journeyType, deviceCategory) =>{
    // journey type value is "Crosssell"
    // as we don't have exact Journey type value here
    try{

            const payload = {
            event: "Select Device Type- Crossell",
            action: "Click",
            category: "Booking Flow",
            label: "Select Device Type- Crossell-Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// Protection
export const crossellDevicePriceProtection = (journeyType, deviceCategory) =>{
    // journey type value is "Crosssell"
    // as we don't have exact Journey type value here
    try{

            const payload = {
            event: "Device Price Value - Crossell",
            action: "Add value",
            category: "Booking Flow",
            label: "Device Price Value - Crossell - Add Values",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// Protection
export const crossellViewPlanButtonProtection = (journeyType, deviceCategory) =>{
    // journey type value is "Crosssell"
    // as we don't have exact Journey type value here
    try{

            const payload = {
            event: "View Plan Button Additional Device - Crossell",
            action: "Click",
            category: "Booking Flow",
            label: "View Plan Button Additional Device - Crossell- Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// Protection, AMC
export const closeCrossellModal = (journeyType, deviceCategory) =>{

    try{

            const payload = {
            event: "Close Add Additional Device Popup - Crossell",
            action: "Click",
            category: "Booking Flow",
            label: "Close Add Additional Device Popup - Crossell - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// Coupon Code Enter
export const enterCouponCode = (journeyType, deviceCategory) =>{

    try{

            const payload = {
            event: "Enter Coupon Code",
            action: "Add value",
            category: "Booking Flow",
            label: "Enter Coupon Code - Add Value",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// Apply Coupon
export const applyCoupon = (journeyType, deviceCategory) =>{

    try{

            const payload = {
            event: "Apply Coupon Code",
            action: "Click",
            category: "Booking Flow",
            label: "Apply Coupon Code - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

export const planTypeClickMoreServices = (journeyType, deviceCategory, categoryType) =>{
    try{
            if(['Basic Chimney','Island Chimney','Window AC','Split AC', 'UV Water Purifier', 'RO Water Purifier'].includes(deviceCategory)) {

                const payload = {
                event: "Insta Repair Plan Type more services",
                action: "Click",
                category: "Booking Flow",
                label: "Insta Repair Plan Type more services - Click",
                device_category: categoryType,
                journey_type: journeyType,
                journey_starts_from: localStorage.getItem('journey_starts_from')
                }
                pushToDatalayer("", payload)

            } else {

                const payload = {
                    event: "Insta Repair Plan Type more services",
                    action: "Click",
                    category: "Booking Flow",
                    label: "Insta Repair Plan Type more services - Click",
                    device_category: deviceCategory,
                    journey_type: journeyType,
                    journey_starts_from: localStorage.getItem('journey_starts_from')
                }
                pushToDatalayer("", payload)
            }

    }catch{

        console.log('error while pushing to datalater')
    }
}

export const planTypeClickCrossell = (journeyType, deviceCategory, categoryType) =>{

    try{
            if(['Basic Chimney','Island Chimney','Window AC','Split AC', 'UV Water Purifier', 'RO Water Purifier'].includes(deviceCategory)) {

                const payload = {
                    event: "Plan Type - Crosssell",
                    action: "Click",
                    category: "Booking Flow",
                    label: "Plan Type - Crosssell - Click",
                    device_category: categoryType,
                    journey_type: journeyType,
                    journey_starts_from: localStorage.getItem('journey_starts_from')
                }
                pushToDatalayer("", payload)

            }else {

                const payload = {
                    event: "Plan Type - Crosssell",
                    action: "Click",
                    category: "Booking Flow",
                    label: "Plan Type - Crosssell - Click",
                    device_category: categoryType,
                    journey_type: journeyType,
                    journey_starts_from: localStorage.getItem('journey_starts_from')
                }
                pushToDatalayer("", payload)
            }

    }catch{

        console.log('error while pushing to datalater')
    }
}

// Insta Upsell and Crossell GO to Cart Button
export const upsellCrossellCartButtonInsta = (journeyType, deviceCategory, categoryType, crossellSkip) =>{
    if(['Basic Chimney','Island Chimney','Hob'].includes(deviceCategory)){
        journeyType = 'AMCInsta';
    }

    try{
            var cat ='';
            if(['Basic Chimney','Island Chimney','Window AC','Split AC', 'UV Water Purifier', 'RO Water Purifier'].includes(deviceCategory)) {

                cat = categoryType;

            }else {

                cat = deviceCategory

            }

            if(crossellSkip){
                // UpsellConditionGoes Here

                const payload = {
                event: "Insta Repair Go to Cart Button  more service",
                action: "Click",
                category: "Booking Flow",
                label: "Insta Repair Go to Cart Button more services- Click",
                device_category: cat,
                journey_type: journeyType,
                journey_starts_from: localStorage.getItem('journey_starts_from')
                }
                pushToDatalayer("", payload)

            } else {


                const payload = {
                event: "Go to Cart Button - Crosssell",
                action: "Click",
                category: "Booking Flow",
                label: "Go to Cart Button - Crosssell - Click",
                device_category: cat,
                journey_type: journeyType,
                journey_starts_from: localStorage.getItem('journey_starts_from')
                }
                pushToDatalayer("", payload)

            }

    }catch{

        console.log('error while pushing to datalater')
    }
}

// InstaRepair, AMC-AC, AMC-WP Crossell Modal DeviceType Click
export const crossellDeviceTypeClick = (journeyType, deviceCategory) =>{

    try{

            const payload = {
            event: "Device Type - Crosssell",
            action: "Click",
            category: "Booking Flow",
            label: "Device Type - Crosssell - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// AMC-AC Crossell Device Capacity Click
export const crossellDeviceCapacityClickAMC = (journeyType, deviceCategory) =>{

    try{

            const payload = {
            event: "Device Capacity - Crosssell",
            action: "Click",
            category: "Booking Flow",
            label: "Device Capacity - Crosssell - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// AMC-AC AMC-WP Crossell Device Brand Click
export const crossellDeviceBrandClickAMC = (journeyType, deviceCategory) =>{

    try{

            const payload = {
            event: "Device Brand - Crosssell",
            action: "Click",
            category: "Booking Flow",
            label: "Device Brand - Crosssell - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// AMC-AC AMC-WP Crossell View Plan Button Click
export const crossellViewPlanButtonAMC = (journeyType, deviceCategory) =>{

    try{

            const payload = {
            event: "View Plan Button - Crosssell",
            action: "Click",
            category: "Booking Flow",
            label: "View Plan Button - Crosssell - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// AMC-AC  Crossell Plan Type Click
export const crossellPlanTypeAMC = (journeyType, deviceCategory) =>{
    if(['Split AC','Window AC'].includes(deviceCategory)){
        deviceCategory = 'Air Conditioner';
    }else if(['Basic Chimney','Island Chimney'].includes(deviceCategory)){
        deviceCategory = 'Chimney';
    }

    try{

            const payload = {
            event: "Plan Type - Crosssell",
            action: "Click",
            category: "Booking Flow",
            label: "Plan Type - Crosssell - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// AMC-AC  Crossell View Plan Button Click
export const crossellPlanAndYearAMC = (journeyType, deviceCategory) =>{
    if(['Split AC','Window AC'].includes(deviceCategory)){
        deviceCategory = 'Air Conditioner';
    }else if(['Basic Chimney','Island Chimney'].includes(deviceCategory)){
        deviceCategory = 'Chimney';
    }

    try{

            const payload = {
            event: "Plan and Year Button - Crosssell",
            action: "Click",
            category: "Booking Flow",
            label: "Plan and Year Button - Crosssell - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}

// AMC-AC AMC-WP Crossell Add to Cart Button Click
export const crossellAddToCartAMC = (journeyType, deviceCategory) =>{

    try{

            const payload = {
            event: "Add to Cart Button - Crosssell",
            action: "Click",
            category: "Booking Flow",
            label: "Add to Cart Button - Crosssell - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}
// export const addCity = (category) =>{
//     if(category == 'protection-plan'){
//         category = "Protection Plan"
//     }else if(category == 'amc'){
//         category = 'AMC'
//     }else if(category == 'insta-repair'){
//         category = 'Insta Repair'
//     }
//     try{
//         const payload = {
//             event: "City",
//             action: "Add Values",
//             category: category+" Booking Flow",
//             section: "City",
//             label:"City - Add Values"
//         }
//         pushToDatalayer("", payload)
//     }catch{
//         console.log('error while pushing to datalater')
//     }

// }

// export const addState = (category) =>{
//     if(category == 'protection-plan'){
//         category = 'Protection Plan'
//     }else if(category == 'amc'){
//         category = 'AMC'
//     }else if(category == 'insta-repair'){
//         category = 'Insta Repair'
//     }
//     try{
//         const payload = {
//             event: "State",
//             action: "Add Values",
//             category: category+" Booking Flow",
//             section: "State",
//             label: "State - Add Values"
//             }
//         pushToDatalayer("", payload)
//     }catch{
//         console.log('error while pushing to datalater')
//     }

// }

// Remaining EVENTS
export const proceedToPaymentClick = (journeyType, deviceCategory) =>{

    try{

        const payload = {

            event: "Proceed To Payment Button",
            action: "Click",
            category: "Booking Flow",
            label: "Proceed To Payment Button - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }

}

export const paymentSuccess = (journeyType, deviceCategory) =>{

    try{

        const payload = {
        event: "Payment Successful",
        action: "Page load",
        category: "Booking Flow",
        label: "Payment Successful - Page load",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }

}

export const paymentFailed = (journeyType, deviceCategory) =>{

    try{

        const payload = {

            event: "Payment Failed",
            action: "Page load",
            category: "Booking Flow",
            label: "Payment Failed - Page load",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }

        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')

    }
}

// Insta PayLater
export const payLaterInsta = (journeyType, deviceCategory) =>{

    try{

        const payload = {

            event: "Pay After Service",
            action: "Click",
            category: "Booking Flow",
            label: "Pay After Service - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }

        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')

    }
}

export const payLaterPaymentSuccess = (journeyType, deviceCategory) =>{

    try{

        const payload = {
        event: "Pay After Service I agree",
        action: "Click",
        category: "Booking Flow",
        label: "Pay After Service I agree - Click",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }

}

export const payLaterPaymentFailed = (journeyType, deviceCategory) =>{
    try{

        const payload = {

            event: "Pay After Service I disagree",
            action: "Click",
            category: "Booking Flow",
            label: "Pay After Service I disagree - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }

        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')

    }
}

// MultiCart Helper Function
export const multiCartHelperFunction = (cart, cartType, functionName) =>{

    try{

        var multicart;

        if (cartType == 'protection-plan'){

            cart.items.length > 1 ? multicart = 'Yes' : multicart = 'No';

            functionName('Protection Plan', multicart);

        }else if (cartType == 'amc') {
            let isInsta = cart.items.some(item => item.group=='insta-repair')

            cart.items.length > 1 ? multicart = 'Yes' : multicart = 'No';
            if(isInsta){
                functionName("AMCInsta", multicart);
            }else{
                functionName("AMC", multicart);
            }    
        }else if (cartType == 'insta-repair') {
            let isAmc = cart.items.some(item => item.group=='amc')

            if(cart.items.length === 1){

                if(cart.items[0].quantity > 1 ){

                    multicart = 'Yes';

                }else {

                    multicart = 'No';
                }

            }else {

                multicart = 'Yes';
            }
            if(isAmc){
                functionName("AMCInsta", multicart);
            }else{
                functionName("Insta", multicart);
            }

        }

    }catch{

        console.log('error while pushing to datalater in helperFunction')

    }
}
// MultiCart Event
export const proceedToPaymentClickMultiCart = (journeyType, multicart) =>{

    try{

        const payload = {

            event: "Proceed To Payment Button",
            action: "Click",
            category: "Cart Checkout Flow",
            label: "Proceed To Payment Button - Click",
            multicart: multicart,
            journey_type: journeyType
        }
        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }

}

// MultiCart EVENT
export const paymentSuccessMultiCart = (journeyType, multicart) =>{

    try{

        const payload = {

        event: "Payment Successful",
        action: "Click",
        category: "Cart Checkout Flow",
        label: "Payment Successful - Page load",
        multicart: multicart,
        journey_type: journeyType
    }
        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }

}

// MultiCart EVENT
export const paymentFailedMultiCart = (journeyType, multicart) =>{

    try{

        const payload = {

            event: "Payment Failed",
            action: "Click",
            category: "Cart Checkout Flow",
            label: "Payment Failed - Page load",
            multicart: multicart,
            journey_type: journeyType,
        }

        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')

    }
}

// Insta PayLaterMultiCart
export const payLaterInstaMultiCart = (journeyType, multicart) =>{

    try{

        const payload = {

            event: "Pay After Service",
            action: "Click",
            category: "Cart Checkout Flow",
            label: "Pay After Service - Click",
            multicart: multicart,
            journey_type: journeyType,
        }

        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')

    }
}

export const payLaterSuccessMultiCart = (journeyType, multicart) =>{

    try{

        const payload = {
        event: "Pay After Service I agree",
        action: "Click",
        category: "Cart Checkout Flow",
        label: "Pay After Service I agree - Click",
        multicart: multicart,
        journey_type: journeyType
    }
        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }

}

export const payLaterFailedMultiCart = (journeyType, multicart) =>{

    try{

        const payload = {

            event: "Pay After Service I disagree",
            action: "Click",
            category: "Cart Checkout Flow",
            label: "Pay After Service I disagree - Click",
            multicart: multicart,
            journey_type: journeyType,
        }

        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')

    }
}

export const helperFunctionTotalSrGeneration = (cart,cartType, functionName) => {
    try{

        const unique = {}

        if (cartType == 'insta-repair' || cartType == 'amc' || cartType == 'AMCInsta') {
            cart.items.map(function(obj) {
                // unique[obj.category] = unique[obj.category] || []
                // !unique[obj.category].includes(obj.product_type) ? unique[obj.category].push(obj.product_type) : null;
                if(obj.group=='insta-repair'){
                    if(['Island Chimney','Basic Chimney','Window AC','Split AC', 'UV Water Purifier', 'RO Water Purifier'].includes(obj.category)){
                        unique[obj.category_type] = unique[obj.category_type] || []
                        if(obj.group == 'insta-repair' && !(obj.category_type == 'Chimney')){
                            !unique[obj.category_type].includes("Insta") ? unique[obj.category_type].push("Insta") : null;
                        }else if(obj.group=='amc' && !(obj.category_type == 'Chimney')){
                            !unique[obj.category_type].includes("AMC") ? unique[obj.category_type].push("AMC") : null;
                        }else if(obj.category_type == 'Chimney'){
                            !unique[obj.category_type].includes("AMCInsta") ? unique[obj.category_type].push("AMCInsta") : null;
                        }

                    }else {

                        unique[obj.category] = unique[obj.category] || []
                        if(obj.group == 'insta-repair' && !(obj.category == 'Hob')){
                            !unique[obj.category].includes("Insta") ? unique[obj.category].push("Insta") : null;
                        }else if(obj.group=='amc' && !(obj.category == 'Hob')){
                            !unique[obj.category].includes("AMC") ? unique[obj.category].push("AMC") : null;
                        }else if(obj.category == 'Hob'){
                            !unique[obj.category].includes("AMCInsta") ? unique[obj.category].push("AMCInsta") : null;
                        }   
                    }
                }
            })
            for (const obj in unique){
                unique[obj].map(function(item) {
                    functionName(item, obj)
                })
            }

        } 
    }catch{
        console.log('error while pushing to datalater in helperFunction')
    }
}
export const totalSRGenerated = (journeyType,deviceCategory) =>{
    try{

        const payload = {
        event: "Total SR generated",
        action: "Page load",
        category: "Booking Flow",
        label: "Total SR generated – Page load",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
        pushToDatalayer("", payload)

    }catch{

        console.log('error while pushing to datalater')
    }
}
