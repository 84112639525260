import styles from '../../../styles/modules/ui-components/add_cart_button.module.scss';
import { addQuantityClicked, addToCartClicked, reduceQuantityClicked } from '../../../tracking/segment/planPage';
import { getProductName } from '../../../utils/tracking';

const AddCartButton = props => {
  let parentStyles = props.styles
  let isEnableAddButton = props.isEnableAddButton;
  return (
    <div className={` ${styles.add_cart_button_holder} ${isEnableAddButton? '' : styles.disable_button } col-3 col-sm-3 col-md-3 col-lg-3`}>
    {
      props.quantity !== 0 ?
      <div className={styles.has_quantity}> 
        <a onClick={ (e) => {reduceQuantityClicked({'sku_name':props.title, 'sku_price':props.price, 'quantity':props.cart.quantity-1 ,'previous_quantity':props.cart.quantity});props.decrementQuantity(e)}}><i>-</i></a>
        <span className={styles.quantity}>{props.quantity}</span>
        <a onClick={ (e) => {addQuantityClicked({'sku_name':props.title, 'sku_price':props.price, 'quantity':props.cart.quantity+1,'previous_quantity':props.cart.quantity});props.incrementQuantity(e)}}><i>+</i></a>
      </div>
      :
      <a className={`${styles.add_btn} ${parentStyles.app_btn}`} data-loading={props.response} onClick={(e) => { addToCartClicked({ 'sku_name':props.title, 'sku_price':props.price, 'previous_quantity':props.cart.quantity?props.cart.quantity:0, 'quantity':props.cart.quantity?props.cart.quantity+1:1,'category':props.category, 'product_type':props.product_type, 'product_name':getProductName(props),'product_group':'amc,insta-repair' }); props.incrementQuantity(e,true)}}>Add<sup>+</sup></a>
    }
    </div>
    )
}

export default AddCartButton;
