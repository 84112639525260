import React from 'react'
import { GetCart } from '../../api/cart';
import { CHECK_EXISTING_CART } from '../../redux/actionTypes/cartActionTypes';
import store from '../../redux';
import { setSelectedCityInCookie } from '../../utils/cities';

let hidden, visibilityChange;
interface State{
  isVisible: boolean
}

class VisibilityManager extends React.Component<any, State> {
  state:State = {
    isVisible: true
  };

  componentDidMount() {
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    }
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    window.addEventListener("focus", this.handleVisibilityChange, false);
    window.addEventListener("blur", this.handleVisibilityChange, false);
  }

  componentWillUnmount(){
    document.removeEventListener(visibilityChange,this.handleVisibilityChange)
    window.removeEventListener("focus", this.handleVisibilityChange, false);
    window.removeEventListener("blur", this.handleVisibilityChange, false);
  }

  handleVisibilityChange = () => {
    if (document[hidden]) {
      return this.setVisibility(false);
    }
    if (!store.getState().profile.username) {
      var cart_id = store.getState().cart.token_id
        cart_id && GetCart(cart_id)
        .then((res:any) => {
            store.dispatch({
                type: CHECK_EXISTING_CART,
                data: res.data,
            })
            if(res.data.lms_order && res.data.city){
              setSelectedCityInCookie(res.data.city)
            }
            if(window.location.href.includes('cart')) {
              if(res.data.items.length < 1) {
                window.location.href = window.location.origin
              }
            }
        })
        .catch((err:any) => {
          console.log(err)
          window.location.href = window.location.origin
      })
    }
    return this.setVisibility(true);
  };

  setVisibility = flag => {
    this.setState(prevState => {
      if (prevState.isVisible === flag) return null;
      return { isVisible: flag };
    });
  };

  render() {
    return typeof this.props.children === 'function' && this.props.children(this.state.isVisible)
  }
}

export default VisibilityManager;
