import React from 'react';
import styles from '@/styles/PremiumCards/premium-badge.module.scss';
import Image from "next/legacy/image";

interface Props {
  details: {
    icon_url?: string | null,
    text: string,
    style_name: string,
    icon_name?: string | null
  }
}

const PremiumBadge = (props:Props) => {
  return (
    <div className={`${styles.premium_badge} ${styles[props.details.style_name]}`}>
      <div className={styles.wrapper}>
      {props.details.icon_url &&
        <div className={styles.premium_badge__icon}>
          <Image
            src={`${props.details.icon_url}`}
            alt={props.details.icon_name ? props.details.icon_name : ''}
            height="10"
            width="10"
            style={{
              maxWidth: "100%",
              height: "auto"
            }} />
        </div>
      }
      <p className={styles.premium_badge__title}>{props.details.text}</p>
      </div>
    </div>
  );
}

export default PremiumBadge;
