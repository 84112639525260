import React, { useState } from 'react';
import {connect} from 'react-redux';
import styles from '../../styles/modules/components/device.module.scss';
import Button from '../common/ui-components/input/Button';
import { toggleCartAlert, checkExistingCart, clearCartType} from '../../redux/actions/cartActions';
import { setCityChangePopUpAction } from '../../redux/actions/genericActions';
import { combineParamsString, getApplication, getUtmString, isAgent } from '../../utils';
import { useRouter } from 'next/router';
import { UpdateCartCity } from '../../api/cart';
import { getSelectedCityFromCookie, setSelectedCityInCookie } from '../../utils/cities';
import CITIES_SLUG from '../../city_constants';
import { cityChangePopupClose, cityChangePopupOpen } from '../../tracking/segment/planPage';

const CityChangePopUp = (props:any) => {
  const [title, setTitle] = useState<string>("")
  const [text, setText] = useState<string>("cart items will be emptied;")

  const router:any = useRouter()
  
  const cancelClick = () => {
    let path:any = router.pathname;
    let cookie_city = getSelectedCityFromCookie()
    if(!isAgent() && props.state?.editAddressId){
      props.slotSelectionShown(false)
      props.updateAddressEditId(null)
    }
    if( props.slug_mapping && cookie_city != CITIES_SLUG[props.slug_mapping?.city].city){
      router.push("/")
    }
    if(path.includes('cart')){
      props.updateLocalState({pincode: "", city: "", state: "" })
    }
    cityChangePopupClose({'source':props.source,'value':'Not Changed','current_city':cookie_city,'updated_city':''})
    props.setCityChangePopUpAction({state:false})
  }

  const clearCart = () => {
    let city = props.genericReducer?.city_data;
    let cookie_city = getSelectedCityFromCookie()
    if(city){
      props.cart.token_id && UpdateCartCity(props.cart.token_id,{'application':getApplication(), 'city':city.name}).then((res:any)=>{
        props.checkExistingCart(res.data)
        props.clearCartType()
        cityChangePopupClose({'source':props.source,'value':'Changed','current_city':cookie_city, 'updated_city':city.name})
        if(city && city.url){
          router.push(combineParamsString(city.url, getUtmString()))
          setSelectedCityInCookie(city.name)
        }else{
          router.push('/')
          setSelectedCityInCookie(city.name)
        }
        props.setCityChangePopUpAction({ state:false })
        })
    }
  }

  React.useEffect(()=>{
    let city = props.genericReducer?.city_data;
    let cookie_city = getSelectedCityFromCookie()
    if(city && city.name!=cookie_city && city.url && Object.keys(city).length && props.cart.quantity==0){
      router.push(combineParamsString(city.url, getUtmString()))
      setSelectedCityInCookie(city.name)
      props.setCityChangePopUpAction({ state:false })
    }
    if(!cookie_city && city.name){
      setSelectedCityInCookie(city.name)
      props.setCityChangePopUpAction({ state:false })
    }
    if(props.genericReducer?.cityChangePopup && city && props.cart.quantity > 0){
      cityChangePopupOpen({'source':props.source?props.source:'', 'current_city':cookie_city, 'updated_city':city.name})
    }
  },[props.genericReducer.city_data, props.cart.quantity])

  React.useEffect(()=>{
      setText("Location is outside "+ getSelectedCityFromCookie())
      setTitle("Cart items will be emptied, do you want to continue?")
  },[getSelectedCityFromCookie()])

  return (
    <React.Fragment>
      <div className={styles.cart_alert}>
        <h5 className={styles.cart_alert__title}>{text}</h5>
        <div className={styles.cart_alert__text}>
          {title}
        </div>
      </div>
      <div className={styles.selection_actions_common}>
          <Button
            onClick={clearCart}
            text="CONTINUE"
          />
          <Button
            onClick={cancelClick}
            text="CANCEL"
          />
        </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state:any) => {
  return {
    cart: state.cart,
    genericReducer: state.genericReducer
  }
}

const mapDispatchToProps = (dispatch:any) => {
  return {
    toggleCartAlert: (data:any) => dispatch(toggleCartAlert(data)),
    setCityChangePopUpAction: (data:any) => dispatch(setCityChangePopUpAction(data)),
    checkExistingCart: (data: any) => dispatch(checkExistingCart(data)),
    clearCartType: () => dispatch(clearCartType()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityChangePopUp)
