import React from 'react';
import styles from '@/styles/PremiumCards/offer-details.module.scss';
import Image from "next/legacy/image";
interface Props {
  details: {
    icon_name: string,
    icon_url: string,
    text: string,
  }
}

const OfferDetails = (props:Props) => {
  const {icon_name, icon_url, text} = props.details;

  React.useEffect(() => {
    let textElement = document.getElementById(`offer-icon-${icon_name}`);
    if (textElement) {
      textElement.innerHTML = text;
    }
  }, [])

  return (
    <div className={styles.offer_details}>
      {
        icon_url ?
        <div className={styles.offer_icon}>
          <Image
            src={icon_url}
            alt={icon_name || ''}
            style={{
              maxWidth: "100%",
              height: "auto"
            }} />
        </div>
        :
        null
      }
      <div id={`offer-icon-${icon_name}`} className={styles.offer_text}>
      </div>
    </div>
  );
}

export default OfferDetails;
