import React, { Component } from "react";
import ReactPlayer from "react-player";
import styles from '@/styles/PremiumCards/video-player.module.scss';
import ProgressBar from "@/components/plans/PremiumCards/ProgressBar";
import {detectBrowser} from '../../../../../utils'


interface Props {
  details: {
    video_url: string,
    thumbnail_url?: string | null,
    aspect_ratio: string | null,
    handleDrawer: any,
    drawerOpen: boolean,
    label_icon_url: string | null,
    items_list: {
      icon_name: string | null,
      icon_url: string,
      title: string,
      sub_title: string
    }[],
    banner_image_url: string,
  },
  visible?: boolean,
}

interface State {
  maxPlayerWidth: number,
  maxWindowHeight:number
  playerHeight: number,
  loopCount: number,
  mediaDuration: number,
  progress: number,
  browserName: string,
  playerStarted: boolean,
  attempt: number,
  videoElement: any,
  inView: boolean
}

const horizontalPadding:number = (15 * 2) + (11 * 2) + (10 * 2) // container padding + card list padding + card padding
const maxLoops = 3;

class VideoPlayer extends Component<Props, State> {
  state:State = {
    maxPlayerWidth: 360,
    maxWindowHeight:1090,
    playerHeight: 240,
    loopCount: 0,
    mediaDuration: 1,
    progress: 0,
    browserName: '',
    playerStarted: false,
    attempt: 0,
    videoElement: null,
    inView: false,
  }

  player:any = null;

  getPlayerBreakpoints = (width) => {
    const breakpoints = {
      360: 5,
      325: 5 + (325 - width),
      320: 10
    }

    let breakpointKey:number = 361;
    Object.keys(breakpoints).sort().some(breakpoint => {
      if (width < breakpoint) {
        breakpointKey = parseInt(breakpoint);
        return true;
      }
    });

    if (breakpoints[breakpointKey]) {
      return breakpoints[breakpointKey];
    }
    return 0;
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.attemptForcePlay)
    let innerWidth = window.innerWidth + this.getPlayerBreakpoints(window.innerWidth) - horizontalPadding;
    if (window.innerWidth > 575) {
      // above 575px upto 767px, container width is fixed at 540px
      innerWidth = 540 - horizontalPadding;
    }
    if (window.innerWidth < 360) {
      innerWidth = window.innerWidth + this.getPlayerBreakpoints(window.innerWidth) - horizontalPadding
    }
    const browserName = detectBrowser()
    this.setState({
        maxPlayerWidth: innerWidth,
        maxWindowHeight: window.innerHeight,
        browserName: browserName,
      }, () => {
        this.setState({
          playerHeight: this.calculateVideoDimensions()
        })
      }
    );
    if (this.player) {
      this.setState({videoElement: this.player.getInternalPlayer()})
    }
  }

  componentDidUpdate = () => {
    if(this.state.videoElement !== this.player?.getInternalPlayer()) {
      this.setState({videoElement: this.player?.getInternalPlayer()})
    }
  }

  calculateVideoDimensions = () => {
    let height = this.state.maxPlayerWidth;
    let widthMultiplier = 16;
    let heightMultiplier = 9;
    if (this.props.details.aspect_ratio) {
      widthMultiplier = parseInt(this.props.details.aspect_ratio.split(':')[0])
      heightMultiplier = parseInt(this.props.details.aspect_ratio.split(':')[1])
    }
    height = this.state.maxPlayerWidth * (heightMultiplier/widthMultiplier);
    return Math.round(height);
  }

  updateLoops = () => {
    this.setState({loopCount: this.state.loopCount + 1})
    if (this.state.loopCount < maxLoops && this.player) {
      setTimeout(() => {
        this.player.seekTo(0);
        if (this.state.browserName === "Safari") {
          this.player?.getInternalPlayer().play();
        }
      }, 500);
    }
  }

  handleReplay = () => {
    this.player.seekTo(0);
    this.setState({loopCount: 0, progress: 0});
  }

  handleDrawer = () => {
    if (this.props.details.items_list.length > 0) {
      this.props.details.handleDrawer(!this.props.details.drawerOpen);
    }
  }

  handleMediaDuration = (duration) => {
    this.setState({
      mediaDuration: duration
    });
  }

  handleProgress = (progress) => {
    this.setState({
      progress: progress.playedSeconds >= this.state.mediaDuration - 0.1 ? this.state.mediaDuration : progress.playedSeconds
    });
  }

  handlePlay = () => {
    this.setState({
      playerStarted: true,
    })
  }

  attemptForcePlay = () => {
    if(!this.state.inView) {
      if((this.state.videoElement?.getBoundingClientRect().y < (this.state.maxWindowHeight - this.state.playerHeight - 60) &&
      this.state.videoElement?.getBoundingClientRect().y > 60)) {
        this.setState({inView: true})
      }
    } else {
      if((this.state.videoElement?.getBoundingClientRect().y >= (this.state.maxWindowHeight - this.state.playerHeight - 60) ||
      this.state.videoElement?.getBoundingClientRect().y < 60)) {
        this.setState({inView: false})
      }
    }
  }


  ref = player => {
    this.player = player
  }

  render() {
    return (
        <div className={styles.video_player}>
          <ReactPlayer
            width={this.state.maxPlayerWidth}
            height={'auto'}
            playing={
              this.state.inView && this.state.loopCount < maxLoops
            }
            url={this.props.details.video_url}
            onEnded={this.updateLoops}
            ref={this.ref}
            onProgress={this.handleProgress}
            onDuration={this.handleMediaDuration}
            progressInterval={100}
            style={{marginBottom: -3}}
            playsinline={true}
            onPlay={this.handlePlay}
            volume={1}
            muted={true}
          />
          <ProgressBar maxValue={this.state.mediaDuration} currentValue={this.state.progress} width={this.state.maxPlayerWidth}/>
          {this.state.loopCount >= maxLoops && <div className={styles.overlay} style={{
              width:this.state.maxPlayerWidth,
              maxHeight:this.state.playerHeight,
            }}>
            <span onClick={this.handleReplay}>
              <div className={styles.replayButton}></div>
              {/* this needs to be the icon */}
            </span>
          </div>}
          {this.props.details.label_icon_url ? <div
            className={styles.watermark}
            style={{
              background: `url(${this.props.details.label_icon_url ? this.props.details.label_icon_url : ''}) no-repeat center`,
              backgroundSize: 'contain,cover'
            }}
            onClick={this.handleDrawer}
          >
          </div> : null}
        </div>
    )
  }
}

export default VideoPlayer;
