export const getBrandObject = (cart_items: [], boolReturnType?: boolean) => {
  let obj:any = {}
  if(cart_items.length > 0) {
    cart_items.forEach((item:any) => {
      let brand = item.additional_details.brand
      if(brand){
        obj = { key: brand, value: brand, text: brand }
        return;
      }
    });
  };
  if(boolReturnType){
    return obj.value ? true : false
  }
  else{
    return obj
  }
}

const getMatchedSKUS = (sku, group, items) => {
  let response:any = {
    quantity: 0
  }
  if(group=='amc' || group=='total-protection'){
    items.filter(item=> item.sku.toString()==sku.toString()).forEach(function(_item){
      response.quantity += 1;
      response.id = _item.id
    })
  }else{
  items.filter(item=> item.sku.toString()==sku.toString()).forEach(function(_item){
    response.quantity = _item.quantity
    response.id = _item.id
    response.brand = _item.device_detail?.brand
  })
}
  return response
}


export const makePlans = (items:any,plans:any) => {
  const plan_categories = Object.keys(plans? plans:{})
  plan_categories.forEach(function(category){
    plans[category] && plans[category].forEach(function(plan,index){
      delete plans[category][index]['id']
      plans[category][index] = {...plans[category][index], ...getMatchedSKUS(plan.sku,plan.group, items)}
    })
  })
  return plans
}

export const formatCardTitle = (title:string) => {
  const DISPLAY_TITLES = {
    'Mobile Phone': 'Mobile',
  }

  if (title.includes("/")) {
    if(title[title.indexOf("/") - 1] !== ' ') {
      title = title.replace("/", " /")
    }
    if(title[title.indexOf("/") + 1] !== ' ') {
      title = title.replace("/", "/ ")
    }
  }

  Object.keys(DISPLAY_TITLES).forEach((key:string) => {
    if(title.includes(key)) {
      title = title.replace(key, DISPLAY_TITLES[key])
    }
  });

  return title;
}
