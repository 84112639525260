import React from "react";
import styles from '@/styles/PremiumCards/progress-bar.module.scss';



interface Props {
  maxValue: number,
  currentValue: number,
  startsWithZero?: boolean,
  width?: number,
}


const ProgressBar = (props:Props) => {
  const calculateProgress = () => {
    let maxValue:number = props.maxValue;
    let currentValue:number = props.currentValue;
    if (maxValue === 0) {
      maxValue = 1;
    }
    if (currentValue === 0) {
      currentValue = maxValue/200;
    }
    if (props.startsWithZero && props.currentValue !== 0) {
      return Math.round(((currentValue + 1)/maxValue) * 100);
    }
    return Math.round((currentValue/maxValue) * 100);
  }

  const isFull = () => {
    return (props.startsWithZero ? (props.currentValue + 1) === props.maxValue : props.currentValue === props.maxValue);
  }

  return (
    <div className={styles.progress_bar} style={{width: props.width ? props.width : '100%'}}>
      <div className={styles.progress_wrapper}>
        <div className={`${styles.progress} ${isFull() ? styles.max_value : ''}`} style={{width:`${calculateProgress()}%`}}></div>
      </div>
    </div>
  )
}


export default ProgressBar;
